import React from 'react';
import LargeSorority from '../../images/redesign-images/rewards/loyalty-path-two.png';
import SmallSorority from '../../images/redesign-images/rewards/loyalty-path-one.png';

const Step0 = ({ onChange, toggleClick }) => {
  return (
    <section className="stack phm" id="step-0">
      <div className="live-quote__header capped row row-end">
        <button className="live-quote__close" onClick={toggleClick}>
          <div className="is-hidden-visually">Close</div>
        </button>
      </div>
      <div className="stack centered">
        <h1 className="c-heading c-heading-lg heading-liberator-light">
          Become a VIP
        </h1>
        <p className="medium medium--res">
          <em>
            Join the UTees VIP Rewards program and start earning credits for
            your sorority or fraternity!
          </em>
        </p>
      </div>

      <div className="capped-medium row row-centered2">
        <div className="col-4--s phm row row-centered2 mbn">
          <button
            className="button-clean loyalty-path-badge"
            type="button"
            onClick={() => onChange('Small Sororities')}
          >
            <img src={SmallSorority} alt="Sororities less than 200 members" />
          </button>
        </div>
        <div className="col-4--s phm row row-centered2 mbn">
          <button
            className="button-clean loyalty-path-badge"
            type="button"
            onClick={() => onChange('Large Sororities')}
          >
            <img src={LargeSorority} alt="Sororities over 200 members" />
          </button>
        </div>
      </div>
    </section>
  );
};

export default Step0;
