import React, { useState, useEffect } from 'react';
import snakecase from 'lodash.snakecase';
import mapKeys from 'lodash.mapkeys';
import { createPortal } from 'react-dom';
import { post } from '../store';
import reCAPTCHABase from '../reCAPTCHABase';
import Step0 from './RewardsApp/Step0';
import Step1 from './RewardsApp/Step1';
import Step2 from './RewardsApp/Step2';
import Step3 from './RewardsApp/Step3';
import Step4 from './RewardsApp/Step4';
import Step5 from './RewardsApp/Step5';

const modalRoot = document.querySelector('#rewards-modal');
const bodyEl = document.querySelector('.site-wrap');

const RewardsApp = ({
  schools,
  organizations,
  buttonClasses,
  buttonText = 'Become a VIP Insider',
}) => {
  const initialFieldState = {
    category: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    school: '',
    organization: '',
    eventOrderEstimate: '',
    prOrderEstimate: '',
    instagram: '',
    apparelChairFirstName: '',
    apparelChairLastName: '',
    apparelChairEmail: '',
    apparelChairPhone: '',
    recruitmentChairFirstName: '',
    recruitmentChairLastName: '',
    recruitmentChairEmail: '',
    recruitmentChairPhone: '',
    philanthropyChairFirstName: '',
    philanthropyChairLastName: '',
    philanthropyChairEmail: '',
    philanthropyChairPhone: '',
  };

  const [isOpen, setIsOpen] = useState(false);

  const [currentStepNumber, setCurrentStepNumber] = useState(0);

  const [form, setForm] = useState(initialFieldState);

  const toggleClick = () => {
    // Toggle the open/close state of the form.
    setIsOpen(v => !v);
    // Start from the first step when the form is closed.
    setCurrentStepNumber(0);
    // Clear the form fields in this step as well.
    setForm(initialFieldState);
  };

  const submitForm = () => {
    const loyalty_form = mapKeys(form, (_, key) => snakecase(key));
    const payload = { loyalty_form };

    reCAPTCHABase('loyalty_form').then(p =>
      post('/rewards', { ...payload, ...p })
    );
  };

  // Prevent body element from scrolling when modal is open
  useEffect(() => {
    if (isOpen) {
      bodyEl.classList.add('contain-content');
    } else {
      bodyEl.classList.remove('contain-content');
    }
    return () => {
      bodyEl.classList.remove('contain-content');
    };
  }, [isOpen]);

  // Dismiss form by hitting escape
  useEffect(() => {
    const handleKeyUp = e => {
      if (e.key === 'Escape') setIsOpen(false);
    };
    document.addEventListener('keyup', handleKeyUp);
    return () => {
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const steps = [
    <Step0
      toggleClick={toggleClick}
      onChange={g => {
        setForm(q => ({ ...q, category: g }));
        setCurrentStepNumber(1);
      }}
    />,
    <Step1
      backButton={() => {
        setCurrentStepNumber(0);
      }}
      toggleClick={toggleClick}
      onChange={q => setForm(q)}
      value={form}
      onSubmit={() => {
        setCurrentStepNumber(2);
      }}
      schools={schools}
      organizations={organizations}
    />,
    <Step2
      backButton={() => {
        setCurrentStepNumber(1);
      }}
      toggleClick={toggleClick}
      onChange={q => setForm(q)}
      value={form}
      onSubmit={() => {
        setCurrentStepNumber(3);
      }}
    />,
    <Step3
      backButton={() => {
        setCurrentStepNumber(2);
      }}
      toggleClick={toggleClick}
      onChange={q => setForm(q)}
      value={form}
      onSubmit={() => {
        setCurrentStepNumber(4);
      }}
    />,
    <Step4
      backButton={() => {
        setCurrentStepNumber(3);
      }}
      toggleClick={toggleClick}
      onChange={q => setForm(q)}
      value={form}
      onSubmit={() => {
        submitForm();
        setCurrentStepNumber(5);
      }}
    />,
    <Step5 toggleClick={toggleClick} />,
  ];

  const currentStep = steps[currentStepNumber];

  return (
    <>
      <button className={buttonClasses} onClick={toggleClick}>
        {buttonText}
      </button>
      {isOpen &&
        createPortal(
          <div className="live-quote-wrapper">
            <div className="live-quote">
              <div className="live-quote__inner">{currentStep}</div>
            </div>
          </div>,
          modalRoot
        )}
    </>
  );
};

export default RewardsApp;
